import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
// import ReportReducer from './reducers/ReportReducer';
// import RackReducer from './reducers/RackReducer';
import ProductReducer from './reducers/ProductReducer';
import thunk from 'redux-thunk';
import { AuthReducer, toggleMenu } from './reducers/AuthReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    sideMenu: toggleMenu,
    // report: ReportReducer,
    // racks:RackReducer,
    products:ProductReducer,
    auth: AuthReducer,	
	
});

export const store = createStore(reducers,  composeEnhancers(middleware));
