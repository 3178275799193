import { Modal, Tab, Form, Nav } from "react-bootstrap";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  createAsset,
  updateAsset,
  deleteUser,
} from "../../../services/OtherService";


const MediaContentModal = ({
  showModal,
  toggleModal,
  setData,
  parentId = null,
}) => {
  const handleOnHide = () => {
    toggleModal();
  };

  const FileUpload = ({ parentId, uploadType }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const inputRef = useRef();

    const handleFileChange = (event) => {
      setError(null);
      const newFiles = Array.from(event.target.files);
      validateFiles(newFiles);
    };

    const handleDrop = (event) => {
      setError(null);
      event.preventDefault();
      const newFiles = Array.from(event.dataTransfer.files);
      validateFiles(newFiles);
    };

    const validateFiles = (files) => {
      const invalidFiles = files.filter((file) => {
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const maxSize = isImage
          ? 10 * 1024 * 1024
          : isVideo
          ? 50 * 1024 * 1024
          : null;
        if (!(isImage || isVideo)) {
          setError("Only image or video files are allowed.");
          return true;
        }

        if (maxSize && file.size > maxSize) {
          setError(
            `${
              isImage ? "Image" : "Video"
            } file size exceeds the allowed limit (${
              maxSize / (1024 * 1024)
            }MB).`
          );
          return true;
        }

        if (isVideo) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const video = document.createElement("video");
            video.src = e.target.result;
            video.onloadedmetadata = function () {
              const videoDuration = video.duration;
              if (videoDuration > 120) {
                setError(
                  `Video file duration exceeds the allowed limit 120 seconds.`
                );
                return true;
              }
            };
          };
          // Read the file as data URL
          reader.readAsDataURL(file);
        }

        // Add additional validation logic if needed

        return false;
      });

      if (invalidFiles.length > 0) {
        return;
      }

      setSelectedFiles([...selectedFiles, ...files]);
      setError(null);
    };

    const handleRemove = (index) => {
      const updatedFiles = [...selectedFiles];
      updatedFiles.splice(index, 1);
      setSelectedFiles(updatedFiles);
    };

    const handleDivClick = () => {
      inputRef.current.click();
    };

    const handleSubmit = async () => {
      if (selectedFiles.length === 0) {
        setError("Please select at least one file.");
        return;
      }
      setIsUploading(true);
      try {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append("file_list", file);
        });
        formData.append("create_type", "FILE");
        if (parentId) {
          formData.append("parent_folder_id", parentId);
        }
        // Replace with your API endpoint
        const resp = await createAsset(formData);
        const resp_data = resp.data.data;
        setData(resp_data);
        toast.success(resp.data.message);
        handleOnHide();
        // Add additional logic here (e.g., show a success message)
      } catch (error) {
        console.error("Error uploading files:", error);
        // Add error handling logic (e.g., show an error message)
      } finally {
        setIsUploading(false);
      }
    };

    return (
      <div>
        <p className="p-0 m-0">
          Hold [Ctrl] or [Shift] to select multiple files.
        </p>
        <small>Maximum resolution allowed for images is 50MP.</small>
        <div
          className="drop-area mt-5"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={inputRef}
            accept={
              uploadType === "MASS"
                ? "image/*,video/*"
                : uploadType === "IMAGE"
                ? "image/*"
                : "video/*"
            }
            onChange={handleFileChange}
            style={{ display: "none" }}
            multiple
          />
          {selectedFiles.length > 0 ? (
            <div>
              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between my-2 bg-light py-1 px-3 rounded"
                >
                  <div>
                    <span className="me-2">
                      {file.type.startsWith("image/") ? (
                        <i className="fa-solid fa-image text-primary"></i>
                      ) : (
                        <i className="fa-solid fa-video text-primary"></i>
                      )}
                    </span>{" "}
                    {file.name}
                  </div>
                  <button
                    type="button"
                    className="btn btn-xs btn-danger"
                    onClick={() => handleRemove(index)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="custom-file-input d-flex align-items-center justify-content-center"
              style={{
                border: "2px dotted #930990",
                padding: "10px",
                cursor: "pointer",
                height: "120px",
                borderRadius: "5px",
              }}
              onClick={handleDivClick}
            >
              <span className="fw-bold fs-large text-primary">
                Click or drag & drop files here
              </span>
            </div>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between">
          <div>
            {selectedFiles.length > 0 && (
              <button
                type="submit"
                className="btn btn-outline-light me-1 btn-sm"
                onClick={handleDivClick}
              >
                Add More
              </button>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary me-1 btn-sm"
              onClick={handleSubmit}
            >
              {isUploading ? (
                <div className="d-flex align-items-center">
                  <div className="my-loader me-2"></div> {/* Loader spinner */}
                  Uploading...
                </div>
              ) : (
                "Upload"
              )}
            </button>
            <button
              type="button"
              onClick={() => toggleModal()}
              className="btn btn-danger light btn-sm ms-1"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const WebPageForm = ({ parentId }) => {
    const [webPageInput, setWebPageInput] = useState({ name: "", url: "" });
    const [webPageInputError, setWebPageInputError] = useState({
      name: { isError: false, msg: "" },
      url: { isError: false, msg: "" },
    });

    const handleOnChange = (e) => {
      const { name, value } = e.target;
      setWebPageInput({ ...webPageInput, [name]: value });
      if (value.length) {
        setWebPageInputError({
          ...webPageInputError,
          [name]: { isError: false, msg: "" },
        });
      } else {
        setWebPageInputError({
          ...webPageInputError,
          [name]: { isError: true, msg: "Required this field" },
        });
      }
    };

    function isValidURL(url) {
      // Regular expression for a simple URL validation
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      // Test the given URL against the regular expression
      return urlRegex.test(url);
    }

    const handleOnSubmit = async () => {
      if (!webPageInput.name.length) {
        setWebPageInputError({
          ...webPageInputError,
          name: { isError: true, msg: "Required this field" },
        });
        return false;
      } else if (!webPageInput.url.length) {
        setWebPageInputError({
          ...webPageInputError,
          url: { isError: true, msg: "Required this field" },
        });
        return false;
      } else if (!isValidURL(webPageInput.url)) {
        setWebPageInputError({
          ...webPageInputError,
          url: { isError: true, msg: "Invalid Web Page URL" },
        });
        return false;
      } else {
        const formData = {
          create_type: "WEBPAGE",
          parent_folder_id: parentId,
          name: webPageInput.name,
          url: webPageInput.url,
        };
        const resp = await createAsset(formData);
        const resp_data = resp.data.data;
        setData(resp_data);
        toast.success(resp.data.message);
        handleOnHide();
      }
    };
    return (
      <div>
        <div className="mb-3">
          <label className="form-label">Web Page Name</label>
          <input
            type="text"
            onChange={handleOnChange}
            value={webPageInput.name}
            name="name"
            className="form-control"
            placeholder="Enter web page name"
          />
          {webPageInputError.name.isError && (
            <span className="text-danger">{webPageInputError.name.msg}</span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Web Page URL</label>
          <textarea
            type="text"
            rows={2}
            name="url"
            className="form-control"
            placeholder="Enter web page URL"
            value={webPageInput.url}
            onChange={handleOnChange}
          ></textarea>
          {webPageInputError.url.isError && (
            <span className="text-danger">{webPageInputError.url.msg}</span>
          )}
        </div>
        {webPageInput.url.length > 0 && (
          <div className="mb-3">
            <iframe
              src={webPageInput.url}
              width={"100%"}
              height={"200px"}
            ></iframe>
          </div>
        )}

        <center>
          <button
            className="btn btn-primary btn-block"
            onClick={handleOnSubmit}
          >
            Submit
          </button>
        </center>
      </div>
    );
  };

  const tabData = [
    {
      name: "Mass Upload",
      content: <FileUpload parentId={parentId} uploadType={"MASS"} />,
    },
    {
      name: "Image",
      content: <FileUpload parentId={parentId} uploadType={"IMAGE"} />,
    },
    {
      name: "Video",
      content: <FileUpload parentId={parentId} uploadType={"VIDEO"} />,
    },
    {
      name: "Web Page",
      content: <WebPageForm parentId={parentId} />,
    },
  ];

  return (
    <Modal
      className="modal fade"
      id="foldermodal"
      show={showModal}
      onHide={handleOnHide}
    >
      <div className="modal-content">
        <div className="d-flex align-items-center justify-content-between px-4 py-3">
          <h1 className="modal-title fs-5" id="exampleModalLabel1">
            Upload Media
          </h1>
          <button
            type="button"
            className="btn-close"
            onClick={handleOnHide}
          ></button>
        </div>
        <div className="modal-body p-2">
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
              <Nav as="ul" className="nav-tabs">
                {tabData.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="p-3">
                {tabData.map((data, i) => (
                  <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                    {data.content}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MediaContentModal;
