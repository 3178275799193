import React, { useState, useEffect } from "react";
import { getLayout } from "../../../services/ProductService";
import AddTagModal from "./AddTagModal";
import { Tab, Nav } from "react-bootstrap";
import MediaPlaylistComponent from "./MediaPlaylist";
import TextPlaylistComponent from "./TextPlaylist";
import PreviewDisplay from "./PreviewScreen";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const ViewLayout = ({ ...props }) => {
  const { id } = useParams()
  const [data, setData] = useState(null);
  const [mediaPlaylist, setMediaPlaylist] = useState([]);
  const [textPlaylist, setTextPlaylist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getLayout(id)
      .then((resp) => {
        setData(resp.data.data);
        setMediaPlaylist(resp.data.data.media_playlist);
        setTextPlaylist(resp.data.data.text_playlist);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsLoading(false);
      });
  }, [id]);


  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-body">
          {isLoading ? (
            <h3>Loading ....</h3>
          ) : data ? (
            <div className="row">
              <div className={`${data.screen_height > data.screen_width ? 'col-sm-4' : 'col-sm-6'}`} style={{ height: "100vh" }}>
                <div className="d-flex flex-column justify-content-start align-items-top">
                  <PreviewDisplay
                    globalImageData={mediaPlaylist}
                    globalTextData={textPlaylist}
                    previewSize="large"
                    isVerticalScreen={data.screen_height > data.screen_width}
                  />
                  {/* <img src="https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg" alt="preview" /> */}
                  <div className="ms-2 mt-2">
                    <div className="d-flex align-items-center justify-content-between">

                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${data.screen_height > data.screen_width ? 'col-sm-8' : 'col-sm-6'}`}
                style={{ height: "100vh", padding: "5px" }}
              >
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey={"media_playlist"}>
                    <Nav as="ul" className="nav-tabs">
                      <Nav.Item as="li" >
                        <Nav.Link eventKey={"media_playlist"}>
                          Media Playlist
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item as="li" >
                        <Nav.Link eventKey={"text_playlist"}>
                          Text Playlist
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="pt-4">
                      <Tab.Pane eventKey={"media_playlist"}>
                        <MediaPlaylistComponent
                          data={data}
                          setData={setData}
                          mediaPlaylist={mediaPlaylist}
                          setMediaPlaylist={setMediaPlaylist}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey={"text_playlist"}>
                        <TextPlaylistComponent
                          data={data}
                          setData={setData}
                          textPlaylist={textPlaylist}
                          setTextPlaylist={setTextPlaylist}
                        />
                      </Tab.Pane>

                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          ) : (
            <h3>No Data Found</h3>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
  };
};

export default connect(mapStateToProps)(ViewLayout);
