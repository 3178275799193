import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";


/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
import Layout from "./components/Layout";
import ViewLayout from "./components/Layout/ViewLayout";
import DevicePage from "./components/Device";
import UserPage from "./components/User";
import MediaLibraryPage from "./components/MediaLibrary";


/// Pages
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Login from "./pages/Login"

import logo from '../images/logo/logo.png';
import logoText from '../images/logo/logo_text.png';

const allroutes = [
  // Dashboard
  { url: "dashboard", element: <Home /> },
  { url: "layout", element: <Layout /> },
  { url: "layout/:id", element: <ViewLayout /> },
  { url: "media-library", element: <MediaLibraryPage /> },
  { url: "user", element: <UserPage /> },
  { url: "device", element: <DevicePage /> },
];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
      <img src={logo} alt="" width={'50px'} style={{ marginRight: '0.6rem' }}/>
      <img src={logoText} alt="" width={'150px'} />
      </div>
    </div>
  )
}

const Markup = ({ isAuthenticated, isCheckingAuth, role, sideMenu }) => {
  const NotFound = () => <Error404 />

  function MainLayout() {
    return (
      <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    )
  }

  const HandleUserAuthentication = ({ data }) => {
    if (!isAuthenticated) {
      return <Navigate to='/' />
    } else if (role === "User" && ["User", "settings"].includes(data.url)) {
      return <Error403 />;
    }
    return data.element
  };

  return (isCheckingAuth ? <FlashScreen /> :
    <>
      <Routes>
        <Route path='/' exact element={isAuthenticated ? <Navigate to='/dashboard' /> : <Login />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={<HandleUserAuthentication data={data} />}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
    sideMenu: state.sideMenu,
    isAuthenticated: state.auth.user,
    isCheckingAuth: state.auth.isCheckingAuth
  };
};

export default connect(mapStateToProps)(Markup);
