import axiosInstance from './AxiosInstance';
const apiUrl = "api/tag"

export function getRackList(page, page_size, query) {
    return axiosInstance.get(`${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getRack(rackId) {
    return axiosInstance.get(`${apiUrl}/${rackId}`);
}
export function getRackProducts(rackId) {
    return axiosInstance.get(`${apiUrl}/${rackId}`);
}


export function createRack(RackData) {
    return axiosInstance.post(apiUrl, RackData);
}

export function updateRack(RackData) {
    return axiosInstance.put(apiUrl, RackData);
}

export function deleteRack(rackId) {
    return axiosInstance.delete(`${apiUrl}/${rackId}`);
}

export function searchRack(page, page_size, query) {
    return axiosInstance.get(`api/search/racks?page=${page}&page_size=${page_size}&query=${query}`);
}

export function searchParentTag(query = '', page = 1, page_size = 20) {
    return axiosInstance.get(`api/search/tag?page=${page}&page_size=${page_size}&query=${query}`);
}

export function searchLayout(query = '', page = 1, page_size = 20) {
    return axiosInstance.get(`api/search/layout?page=${page}&page_size=${page_size}&query=${query}`);
}
export function getUserTagLIst() {
    return axiosInstance.get('api/user_tag');
}

export function searchSource(query) {
    return axiosInstance.get(`api/search/source?&query=${query}`);
}

export function searchEmployee(page='', page_size, query) {
    return axiosInstance.get(`api/search/employee?page=${page}&page_size=${page_size}&query=${query}`);
}

export function searchPurpose(query) {
    return axiosInstance.get(`api/search/purpose?&query=${query}`);
}


export function formatRack(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}
