import { SVGICON } from "../../constant/theme";

export const MenuList = [
    {   
        title:'Dashboard',
        iconStyle: SVGICON.MenuDashboard,
        to: '/dashboard',
    },
    {   
        title:'Layout',
        iconStyle: SVGICON.MenuLayout,
        to: '/layout',
    },
    {   
        title:'Device',
        iconStyle: SVGICON.MenuDevice,
        to: '/device',
    },
    {   
        title:'Media Library',
        iconStyle: SVGICON.MenuMedia,
        to: '/media-library',
    },
    {   
        title:'User',
        iconStyle: SVGICON.MenuUsers,
        to: '/user',
    }    
]