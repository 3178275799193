import React, { useState, useEffect } from 'react';
import { getEmployeeList, createDevice, updateDevice, resetDevice } from '../../../services/OtherService';
import { searchLayout } from '../../../services/RackService'
import Pagination from '../../constant/Pagination';
import { toast } from 'react-toastify';
import { Form, Modal } from 'react-bootstrap';
import { CancelRequest } from '../../../services/AxiosInstance';
import TableLoaderEffect from '../../constant/TableLoader';
import Select from 'react-select'


const DevicePage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [deviceForm, setDeviceForm] = useState({ location: '' });
    const [deviceEditForm, setDeviceEditForm] = useState({ id: '', location: '' });
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    //layout states
    const [layoutOptions, setLayoutOptions] = useState([])
    const [layoutValue, setLayoutValue] = useState(null)
    const [layoutInputValue, setInputLayoutValue] = useState('')
    const [layoutLoaiding, setLayoutLoaiding] = useState(false)

    const recordsPage = 10;

    useEffect(() => {
        setIsLoading(true)
        getEmployeeList(currentPage, recordsPage, searchQuery).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        })

    }, [])

    const fetchLayout = async (query) => {
        setLayoutLoaiding(true)
        const resp = await searchLayout('')
        const { total, data } = resp.data
        setLayoutOptions([...data]);
        setLayoutLoaiding(false)
        setInputLayoutValue(query)
    }

    const toggleModal = async () => {
        if (isModalOpen) {
            setDeviceForm({ location: '' })
            setLayoutOptions([])
            setLayoutValue(null)
        }
        else {
            fetchLayout('')
        }
        setIsModalOpen(!isModalOpen)
    }

    const toggleEditModal = async () => {
        if (isEditModalOpen) {
            setDeviceEditForm({ id: '', location: '' })
            setLayoutOptions([])
            setLayoutValue(null)
        } else {
            fetchLayout('')
        }
        setIsEditModalOpen(!isEditModalOpen)
    }
    
    const toggleResetModal = async () => {
        if (isResetModalOpen) {
            setDeviceEditForm({ id: '', location: '' })
        }
        setIsResetModalOpen(!isResetModalOpen)
    }

    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setDeviceForm({ ...deviceForm, [name]: value })
    }

    const handleChangeEdit = (e) => {
        const { name, value } = e.target
        setDeviceEditForm({ ...deviceEditForm, [name]: value })
    }

    const handlePagination = (page) => {
        setIsLoading(true)
        const new_page = page.selected + 1
        getEmployeeList(new_page, recordsPage, '').then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getEmployeeList(currentPage, recordsPage, value).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }

    const handleLayoutChange = (data) => {
        setLayoutValue(data);
        setInputLayoutValue('')
    }

    const handleLayoutInputChange = async (value, reason) => {
        if (reason.action === 'input-change') {
            fetchLayout(value)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const formData = { ...deviceForm, layout_id: layoutValue.value }
            const resp = await createDevice(formData)
            const resp_data = resp.data.data
            setData([resp_data, ...data])
            setTotal(total + 1)
            toast.success(resp.data.message)
            toggleModal()
        } catch (error) {
            toast.error(error.response.data.message)
        };
    }

    const handleEditSubmit = async (e) => {
        e.preventDefault()
        try {
            const formData = { ...deviceEditForm, layout_id: layoutValue.value }
            const resp = await updateDevice(formData)
            const resp_data = resp.data.data
            setData(data.map(item => {
                if (item.id === formData.id) {
                    return { ...resp_data }
                }
                return item
            }))
            toast.success(resp.data.message)
            toggleEditModal()
        } catch (error) {
            toast.error(error.response.data.message)
        };
    }    
    
    const handleResetSubmit = async (e) => {
        e.preventDefault()
        try {
            const formData = { id:deviceEditForm.id}
            const resp = await resetDevice(formData)
            toast.success(resp.data.message)
            toggleResetModal()
        } catch (error) {
            toast.error(error.response.data.message)
        };
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Device List</h4>
                                        <div className='d-flex'>
                                            <div className='form-group me-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleModal()}
                                            >+ Add Device</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Device ID</th>
                                                    <th>Location</th>
                                                    <th>Layout</th>
                                                    <th>Status</th>
                                                    <th>Last Active</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className='text-primary cursor-pointer fw-bolder'>{item.device_id}
                                                            </span>
                                                        </td>
                                                        <td><span>{item.location}</span></td>
                                                        <td><span>{item.layout.name || "None"}</span></td>
                                                        <td>{item.status ?
                                                            <span className='badge light border-0 badge-success badge-sm'>ONLINE</span> :
                                                            <span className='badge light border-0 badge-danger badge-sm'>OFFLINE</span>
                                                        }
                                                        </td>
                                                        <td><span>{item.last_online}</span></td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <button
                                                                    onClick={() => {
                                                                        const { id, location } = item
                                                                        setDeviceEditForm({ id, location })
                                                                        setLayoutValue({ label: item.layout.name, value: item.layout.id })
                                                                        toggleEditModal()
                                                                    }}
                                                                    className="btn btn-outline-primary btn-sm"
                                                                >
                                                                    <i className="fa-regular fa-pen-to-square me-2"></i> Edit
                                                                </button>
                                                                <button className="btn btn-outline-danger btn-sm ms-2" 
                                                                onClick={() => {
                                                                    const { id, location, device_id } = item
                                                                    setDeviceEditForm({ id, location, device_id })
                                                                    toggleResetModal()
                                                                }}>
                                                                    <i className="fa-solid fa-arrows-rotate me-2"></i> Reset
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add device */}
            <Modal className='modal hide' show={isModalOpen}
                onHide={() => toggleModal()}>
                <div className='modal-content'>
                    <div className="modal-header">
                        <h5 className="modal-title" id="#gridSystemModal">Add Device</h5>
                        <button type="button" className="btn-close"
                            onClick={() => toggleModal()}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <Form onSubmit={(e) => handleSubmit(e)}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">Location <span className="text-danger">*</span></label>
                                            <input type="location" className="form-control" id="location" placeholder="Enter Location"
                                                value={deviceForm.location} name='location' onChange={handleChangeAdd} required />
                                        </div>

                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Layout<span className="text-danger">*</span></label>
                                            <Select
                                                value={layoutValue}
                                                onChange={(data) => handleLayoutChange(data)}
                                                placeholder={"Select Layout"}
                                                isClearable={false}
                                                isLoading={layoutLoaiding}
                                                is_multi={true}
                                                inputValue={layoutInputValue}
                                                options={layoutOptions}
                                                id={"layout"}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onInputChange={(value, reason) => handleLayoutInputChange(value, reason)}
                                            />

                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary me-1">Create</button>
                                    <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* add device end */}


            {/* Edit device */}
            <Modal className='modal hide modal-lg' show={isEditModalOpen}
                onHide={() => toggleEditModal()}>
                <div className='modal-content'>
                    <div className="modal-header">
                        <h5 className="modal-title" id="#gridSystemModal">Edit Device</h5>
                        <button type="button" className="btn-close"
                            onClick={() => toggleEditModal()}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <Form onSubmit={(e) => handleEditSubmit(e)}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="mb-3">
                                            <label htmlFor="location" className="form-label">Location <span className="text-danger">*</span></label>
                                            <input type="location" className="form-control" id="location" placeholder="Enter Location"
                                                value={deviceEditForm.location} name='location' onChange={handleChangeEdit} required />
                                        </div>

                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Layout<span className="text-danger">*</span></label>
                                            <Select
                                                value={layoutValue}
                                                onChange={(data) => handleLayoutChange(data)}
                                                placeholder={"Select Layout"}
                                                isClearable={false}
                                                isLoading={layoutLoaiding}
                                                is_multi={true}
                                                inputValue={layoutInputValue}
                                                options={layoutOptions}
                                                id={"layout"}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onInputChange={(value, reason) => handleLayoutInputChange(value, reason)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary me-1">Update</button>
                                    <button type="button" onClick={() => toggleEditModal()} className="btn btn-danger light ms-1">Cancel</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* add employee end */}


            {/* reset modal */}
            <Modal className='modal hide modal-sm' show={isResetModalOpen}
                onHide={toggleResetModal}>
                <div className='modal-content'>
                    <div className="modal-header">
                        <h5 className="modal-title" id="#gridSystemModal">Reset Device</h5>
                        <button type="button" className="btn-close"
                            onClick={toggleResetModal}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <Form onSubmit={(e) => handleResetSubmit(e)}>
                                <p>Are you sure, that you want to reset the device. where location is <strong>{deviceEditForm.location}</strong> with device ID <strong>{deviceEditForm.device_id}</strong></p>
                                <div className='mt-3 d-flex align-items-center justify-content-center'>
                                    <button type="submit" className="btn btn-primary btn-sm me-3">Reset</button>
                                    <button type="button" onClick={toggleResetModal} className="btn btn-danger light btn-sm ms-1">Cancel</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* reset Modal */}

        </>
    );
};
export default DevicePage;