import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import MediaLibraryWindow from '../MediaLibrary/MediaLIbraryWindow'

const ChooseMediaModal = ({ handleAddMediaPlaylist }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState([])
    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleOnHide = () => {
        if (!isModalOpen) {
            setSelectedMedia([])
        }
        toggleModal()
    }

    const handleSelectedFileOnly = (fileList) => {
        setSelectedMedia([...fileList])
    }

    async function getVideoDuration(videoUrl) {
        try {
          // Fetch the video data as a blob
          const response = await fetch(videoUrl)
          const videoBlob = await response.blob()
      
          const videoElement = document.createElement('video')
          videoElement.src = URL.createObjectURL(videoBlob)
      
          await new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', resolve)
          })
          return Math.floor(videoElement.duration)
        } catch (error) {
          console.error('Error fetching video duration:', error)
          return 1
        }
      }

      const handleSubmit = async () => {
        try {
          const promises = selectedMedia.map(async (item, index) => {
            const newItem = {
              id: null,
              index: index + 1,
              duration:
                item.media.media_content_type === 'Video'
                  ? await getVideoDuration(item.media.url)
                  : 10,
              media: { ...item.media, id: item.id },
            }
            return newItem
          })
      
          const newFormattedMedia = await Promise.all(promises)
      
          handleAddMediaPlaylist(newFormattedMedia)
        } catch (error) {
          console.log('error', error)
        }
        toggleModal()
      }

    return (
        <div>
            <button onClick={handleOnHide} className='btn btn-sm btn-outline-primary'>Add Media</button>
            <Modal className="modal modal-lg fade" id="exampleModal1"
                fullscreen={true}
                scrollable={false}
                show={isModalOpen}
                onHide={() => handleOnHide}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">Choose Media {selectedMedia.length > 0 && `(${selectedMedia.length} file  Selected)`} </h1>
                        <button type="button" className="btn-close" onClick={handleOnHide}></button>
                    </div>
                    <div className="modal-body" style={{ background: "linear-gradient(180deg, #145b845c 0%, #03196c30 55%)" }}>
                        <MediaLibraryWindow setSelectFileOnly={handleSelectedFileOnly} isFileSelect={true} />
                    </div>
                    <div className="modal-footer py-1">
                        <button className='btn btn-sm btn-primary' onClick={handleSubmit}>Select</button>
                        <button className='btn btn-sm btn-outline-danger ms-4' onClick={handleOnHide}>Close</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default ChooseMediaModal

