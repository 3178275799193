import React, { useContext, useEffect, useState } from 'react';

const Home = () => {
	
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-xl-12 wid-100">
					<div className="row">
						<h3>Dashboard</h3>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Home;