import React, { useState, useEffect } from 'react';
import Pagination from '../../constant/Pagination';
import { toast } from 'react-toastify';
import { Form, Modal } from 'react-bootstrap';
import { CancelRequest } from '../../../services/AxiosInstance';
import { getUserList, createUser, updateUser, deleteUser } from '../../../services/OtherService';
import TableLoaderEffect from '../../constant/TableLoader';
import Select from 'react-select'
import { searchParentTag, getUserTagLIst } from '../../../services/RackService'

const UserPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [empForm, setEmpForm] = useState({ name: '', email: '', password: '' });
    const [empEditForm, setEditEmpForm] = useState({ id: '', name: '', email: '', password: '', status: '' });

    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    // tags states
    const [tagOptions, setTagOptions] = useState([])
    const [tagValue, setTagValue] = useState(null)
    const [tagInputValue, setInputTagValue] = useState('')
    const [tagLoaiding, setTagLoaiding] = useState(false)

    const [allTagList, setAllTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [expanded, setExpanded] = useState([]);


    const recordsPage = 10;


    useEffect(() => {
        setIsLoading(true)
        getUserList(currentPage, recordsPage, searchQuery).then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setIsLoading(false)
        }).catch((error) => {
            console.log(error.response.data.message)
            setIsLoading(false)
        })

    }, [])

    const fetchTag = async (query) => {
        setTagLoaiding(true)
        const resp = await searchParentTag('')
        const { total, data } = resp.data
        setTagOptions([...data]);
        setTagLoaiding(false)
        setInputTagValue(query)
    }
    const fetchUserTag = async () => {
        setTagLoaiding(true)
        const resp = await getUserTagLIst()
        const { total, data } = resp.data
        setAllTagList([...data]);
    }

    const toggleModal = () => {
        if (isModalOpen) {
            setEmpForm({ name: '', email: '', password: '' });
            setTagOptions([])
            setTagValue(null)
            setInputTagValue('')
            setTagLoaiding(false)
            setAllTagList([]);
            setSelectedTag([]);
            setExpanded([]);
        } else {
            fetchTag('')
            fetchUserTag()
        }
        setIsModalOpen(!isModalOpen)
    }

    const toggleEditModal = () => {
        if (isEditModalOpen) {
            setEditEmpForm({ id: '', name: '', email: '', password: '', status: '' });
            setTagOptions([])
            setTagValue(null)
            setInputTagValue('')
            setTagLoaiding(false)
            setAllTagList([]);
            setSelectedTag([]);
            setExpanded([]);
        } else {
            fetchTag('')
            fetchUserTag()
        }
        setIsEditModalOpen(!isEditModalOpen)
    }

    const handleChangeAdd = (e) => {
        const { name, value } = e.target
        setEmpForm({ ...empForm, [name]: value })
    }

    const handleChangeEdit = (e) => {
        const { name, value } = e.target
        setEditEmpForm({ ...empEditForm, [name]: value })
    }

    const handlePagination = (page) => {
        setIsLoading(true)
        const new_page = page.selected + 1
        getUserList(new_page, recordsPage, '').then((resp) => {
            setData(resp.data.data)
            setTotal(resp.data.total)
            setCurrentPage(new_page)
            setIsLoading(false)
        }).catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
        })
    }

    const handleSearchChange = (e) => {
        const { value } = e.target
        if (value.length > 0) {
            CancelRequest()
        }
        getUserList(currentPage, recordsPage, value).then((resp) => {
            if (resp) {
                setData(resp.data.data)
                setTotal(resp.data.total)
            }
        }).catch((error) => {
            console.log(error.response.data.message)
        })
        setSearchQuery(value)
    }

    const handleTagChange = (data) => {
        setTagValue(data);
        setInputTagValue('')
    }

    const handleTagInputChange = async (value, reason) => {
        if (reason.action === 'input-change') {
            fetchTag(value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (Object.values(empForm).some(item => item.length === 0)) {
                toast.error("Fill all the Input Fields")
                return false
            }
            if (tagValue === null) {
                toast.error("Select Primary Tag")
                return false
            }
            if (selectedTag.length === 0) {
                toast.error("Select atleast one Tag")
                return false
            }
            const formData = { ...empForm, primary_tag: tagValue.value, tag_list: selectedTag }
            const resp = await createUser(formData)
            const resp_data = resp.data.data
            setData([resp_data, ...data])
            setTotal(total + 1)
            toast.success(resp.data.message)
            toggleModal()
        } catch (error) {
            console.log(error)
            //toast.error(error.response.data.message)
        };

    }


    const handleEditSubmit = async (e) => {
        e.preventDefault()
        try {
            const { name, email, status } = empEditForm;
            if ([name, email, status].some(item => item.length === 0)) {
                toast.error("Fill all the Input Fields")
                return false
            }
            if (tagValue === null) {
                toast.error("Select Primary Tag")
                return false
            }
            if (selectedTag.length === 0) {
                toast.error("Select atleast one Tag")
                return false
            }
            const formData = { ...empEditForm, primary_tag: tagValue.value, tag_list: selectedTag }
            const resp = await updateUser(formData)
            const resp_data = resp.data.data
            setData(data.map(item => {
                if (item.id === empEditForm.id) {
                    return resp_data
                } return item
            }))
            toast.success(resp.data.message)
            toggleEditModal()
        } catch (error) {
            console.log(error)
            //toast.error(error.response.data.message)
        };

    }

    const handleSelectTag = (id) => {
        setSelectedTag((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });

    };

    const isParentSelected = (parentId) => {
        if (!parentId) {
            return false;
        }
        return selectedTag.includes(parentId) || isParentSelected(findParentById(allTagList, parentId)?.id);
    };

    const findParentById = (data, targetId) => {
        for (const item of data) {
            if (item.children && item.children.length > 0) {
                const foundInChildren = findParentById(item.children, targetId);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }

            if (item.children.some(child => child.id === targetId)) {
                return item;
            }
        }

        return null;
    };



    const toggleExpand = (id) => {
        setExpanded((prevExpanded) => {
            if (prevExpanded.includes(id)) {
                return prevExpanded.filter((item) => item !== id);
            } else {
                return [...prevExpanded, id];
            }
        });
    };

    const renderTagTree = (nodes, parentId = null) => {
        return nodes.map((node) => {
            let disabled = isParentSelected(parentId) || selectedTag.some((selectedId) => node.children?.some((child) => child.id === selectedId))
            return (
                <div key={node.id}>
                    <div className='d-flex'>
                        {node.children && node.children.length > 0 && (
                            <span className='c-pointer me-3' onClick={() => toggleExpand(node.id)}>
                                {expanded.includes(node.id) ?
                                    <i className="fa-regular fa-square-minus text-primary" style={{ fontSize: '14px' }}></i> :
                                    <i className="fa-regular fa-square-plus text-primary" style={{ fontSize: '14px' }}></i>}
                            </span>
                        )}
                        <label className='d-flex align-items-center justify-content-start'>
                            <input
                                type="checkbox"
                                checked={selectedTag.includes(node.id)}
                                onChange={() => {
                                    if (!disabled) {
                                        handleSelectTag(node.id)
                                    }
                                }}
                                disabled={disabled}
                            />
                        </label>
                        <span className='ms-2'>{node.name}</span>
                    </div>
                    {node.children && node.children.length > 0 && (
                        <div style={{ marginLeft: '45px' }}>
                            {expanded.includes(node.id) && renderTagTree(node.children, node.id)}
                        </div>
                    )}
                </div>
            )
        });
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Users</h4>
                                        <div className='d-flex'>
                                            <div className='form-group me-5'>
                                                <input type="search" name="serach" className="form-control "
                                                    placeholder="Search" autoFocus
                                                    value={searchQuery} onChange={handleSearchChange} />
                                            </div>
                                            <button className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                                onClick={() => toggleModal()}
                                            >+ Add User</button>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Layouts</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? <TableLoaderEffect colLength={6} /> : data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className='text-primary cursor-pointer fw-bolder'
                                                                onClick={() => {
                                                                    setEditEmpForm({ ...item, password: '' });
                                                                    setTagValue({ label: item.primary_tag?item.primary_tag.name:"", value: item.primary_tag?item.primary_tag.id:"" })
                                                                    setSelectedTag(item.tags)
                                                                    toggleEditModal()
                                                                }}>{item.name}</span></td>
                                                        <td>{item.email}</td>
                                                        <td><span>{item.primary_tag?item.primary_tag.name:"No primary tag"}</span></td>
                                                        <td>
                                                            {item.status === "Active" ? (
                                                                <span className={`badge light border-0 badge-success`}>Active</span>
                                                            ) : (
                                                                <span className={`badge light border-0 badge-danger`}>Inactive</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                            currentPage={currentPage}
                                            recordsPage={recordsPage}
                                            dataLength={total}
                                            handlePagination={handlePagination}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* add employee */}
            <Modal show={isModalOpen}
                onHide={() => toggleModal()}
                className='modal modal-lg'
            >
                <div className='modal-content' >
                    <div className="modal-header">
                        <h5 className="modal-title" id="#gridSystemModal">Add User</h5>
                        <button type="button" className="btn-close"
                            onClick={() => toggleModal()}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <Form onSubmit={(e) => handleSubmit(e)}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                                value={empForm.name} name='name' onChange={handleChangeAdd} required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" id="email" placeholder="Enter Email"
                                                value={empForm.email} name='email' onChange={handleChangeAdd} required />
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Primary Tag<span className="text-danger">*</span></label>
                                            <Select
                                                value={tagValue}
                                                onChange={(data) => handleTagChange(data)}
                                                placeholder={"Select Primary Tags"}
                                                isClearable={true}
                                                isLoading={tagLoaiding}
                                                inputValue={tagInputValue}
                                                options={tagOptions}
                                                id={"tag"}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onInputChange={(value, reason) => handleTagInputChange(value, reason)}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="password" placeholder="Enter Password"
                                                value={empForm.password} name='password' onChange={handleChangeAdd} required />
                                        </div>

                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Select Tags<span className="text-danger">*</span></label>
                                            <div>
                                                {renderTagTree(allTagList)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary me-1">Create</button>
                                    <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Edit employee */}
            <Modal show={isEditModalOpen}
                onHide={() => toggleEditModal()}
                className='modal modal-lg'
            >
                <div className='modal-content' >
                    <div className="modal-header">
                        <h5 className="modal-title" id="#gridSystemModal">Edit User</h5>
                        <button type="button" className="btn-close"
                            onClick={() => toggleEditModal()}>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            <Form onSubmit={(e) => handleEditSubmit(e)}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                                                value={empEditForm.name} name='name' onChange={handleChangeEdit} required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" id="email" placeholder="Enter Email"
                                                value={empEditForm.email} name='email' onChange={handleChangeEdit} required />
                                        </div>
                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Primary Tag<span className="text-danger">*</span></label>
                                            <Select
                                                value={tagValue}
                                                onChange={(data) => handleTagChange(data)}
                                                placeholder={"Select Primary Tags"}
                                                isClearable={true}
                                                isLoading={tagLoaiding}
                                                inputValue={tagInputValue}
                                                options={tagOptions}
                                                id={"tag"}
                                                className='react-select'
                                                classNamePrefix='select'
                                                onInputChange={(value, reason) => handleTagInputChange(value, reason)}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password <span className="text-light">(Optional)</span></label>
                                            <input type="text" className="form-control" id="password" placeholder="Enter New Password"
                                                value={empEditForm.password} name='password' onChange={handleChangeEdit} />
                                        </div>

                                        <div className='mb-3'>
                                            <label className="form-label" htmlFor='parent_tag'>Select Tags<span className="text-danger">*</span></label>
                                            <div>
                                                {renderTagTree(allTagList)}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary me-1">Update</button>
                                    <button type="button" onClick={() => toggleEditModal()} className="btn btn-danger light ms-1">Cancel</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default UserPage;