import React, { useState, useEffect } from "react";
import { Dropdown, DropdownItem, Row, Col } from "react-bootstrap";
import { CancelRequest } from "../../../services/AxiosInstance";
import { getAssetList, getAsset } from "../../../services/OtherService";
import FolderModal from "./FolderModal";
import MediaContentModal from "./MediaContentModal";
import folderIcon from "../../../images/foldericon.png";
import ViewMediaModal from "./ViewMedia";

const MediaLibraryWindow = ({ setSelectFileOnly, isFileSelect = false }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);

  const [isViewMediaOpen, setIsViewMediaOpen] = useState(false);
  const [selectedViewMedia, setSelectedViewMedia] = useState(null);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const recordsPage = 20;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const initialFolder = { id: null, name: "Media Library" };
  const [currentFolder, setCurrentFolder] = useState(initialFolder);
  const [breadcrumbPath, setBreadcrumbPath] = useState([initialFolder]);

  const getMediaLibraryList = (page, query, folder_id = null) => {
    setIsLoading(true);
    getAssetList(page, recordsPage, query, folder_id)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMediaLibraryList(currentPage, searchQuery);
  }, []);

  const toggleFolderModal = () => setIsFolderModalOpen(!isFolderModalOpen);
  const toggleFileModal = () => setIsFileModalOpen(!isFileModalOpen);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
    getMediaLibraryList(new_page, searchQuery, currentFolder.id);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value.length > 0) {
      CancelRequest();
    }
    getMediaLibraryList(1, value, currentFolder.id);
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const handleFolderBreadcrumbClick = (folder) => {
    setBreadcrumbPath((prevPath) => [...prevPath, folder]);
    setCurrentFolder(folder);
  };

  const handleBreadcrumbClick = (index, folder_id) => {
    if (index === breadcrumbPath.length - 1) return;
    setBreadcrumbPath((prevPath) => prevPath.slice(0, index + 1));
    setCurrentFolder(breadcrumbPath[index]);
    setIsLoading(true);
    getMediaLibraryList(1, "", folder_id);
  };

  const toggleViewModal = () => {
    if (isViewMediaOpen) {
      setSelectedViewMedia(null);
    }
    setIsViewMediaOpen(!isViewMediaOpen);
  };

  const handlefolderView = (item) => {
    // on double click on any folder
    if (item.is_folder) {
      handleFolderBreadcrumbClick({ id: item.id, name: item.name });
      setSearchQuery("");
      setCurrentPage(1);
      getMediaLibraryList(1, "", item.id);
    } else {
      setSelectedViewMedia(item);
      toggleViewModal();
    }
  };

  const handleFileSelect = (e, item) => {
    const mediaIndex = selectedFiles.findIndex((media) => media.id === item.id);
    let choosedFileList = [];
    if (mediaIndex !== -1) {
      // If the object exists, remove it
      const updatedArray = [...selectedFiles];
      updatedArray.splice(mediaIndex, 1);
      setSelectedFiles(updatedArray);
      choosedFileList = updatedArray;
    } else {
      setSelectedFiles((prevArray) => [...prevArray, item]);
      choosedFileList = [...selectedFiles, item];
    }
    if (setSelectFileOnly !== undefined) {
      setSelectFileOnly([...choosedFileList]);
    }
  };

  const handleSetData = (new_data) => {
    if (Array.isArray(new_data)) {
      setData([...new_data, ...data]);
    } else {
      setData([new_data, ...data]);
    }
  };

  const onUpdateMedia = (media) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.id === media.id) {
          return media;
        }
        return item;
      });
    });
  };

  const onDeleteMedia = (mediaId) => {
    setData((prevData) => prevData.filter((item) => item.id !== mediaId ));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body py-2">
            <div className="d-flex justify-content-between align-items-center">
              <div
                className="d-flex justify-content-start align-items-center text-black"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                {breadcrumbPath.map((folder, index) => (
                  <span key={folder.name}>
                    {index > 0 ? (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="#000000"
                          viewBox="0 0 256 256"
                        >
                          <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                        </svg>
                      </span>
                    ) : null}

                    <span
                      className={`${
                        index === breadcrumbPath.length - 1
                          ? "text-muted"
                          : "c-pointer"
                      }`}
                      onClick={() => handleBreadcrumbClick(index, folder.id)}
                    >
                      {folder.name}
                    </span>
                  </span>
                ))}
              </div>
              <div className="d-flex">
                <div className="form-group me-5">
                  <input
                    type="search"
                    name="serach"
                    style={{ minWidth: "20rem" }}
                    className="form-control form-control-sm "
                    placeholder={`Search on ${currentFolder.name}`}
                    autoFocus
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    New
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ minWidth: "6.5rem" }}>
                    <Dropdown.Item onClick={toggleFolderModal}>
                      Folder
                    </Dropdown.Item>
                    <Dropdown.Item onClick={toggleFileModal}>
                      File
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>

        <Row>
          {data?.map((item) => (
            <Col
              xl="2"
              sm="6"
              md="3"
              key={item.id}
              className="my-2"
              onClick={(e) => {
                if (isFileSelect && !item.is_folder) {
                  handleFileSelect(e, item);
                }
              }}
              onDoubleClick={() => handlefolderView(item)}
            >
              <div className="folderhover d-flex flex-column align-items-center">
                {item.is_folder ? (
                  <img className="img-folder" src={folderIcon} alt="folder" />
                ) : item.media?.media_content_type === "Image" ? (
                  <img
                    className="img-image"
                    src={item.media?.url}
                    alt="folder"
                  />
                ) : item.media?.media_content_type === "Video" ? (
                  <video src={item.media?.url} className="video"></video>
                ) : (
                  <iframe src={item.media?.url} className="webpage"></iframe>
                )}
                <h6 className="mt-2 single-line-wrap">{item.name}</h6>
                {isFileSelect && !item.is_folder && (
                  <span className="select-icon">
                    {selectedFiles.some((file) => file.id === item.id) ? (
                      <i className="fa-regular fa-circle-check"></i>
                    ) : (
                      <i className="fa-regular fa-circle"></i>
                    )}
                  </span>
                )}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <FolderModal
        showModal={isFolderModalOpen}
        toggleModal={toggleFolderModal}
        setData={handleSetData}
        parentId={currentFolder.id}
      />
      <MediaContentModal
        showModal={isFileModalOpen}
        toggleModal={toggleFileModal}
        setData={handleSetData}
        parentId={currentFolder.id}
      />
      <ViewMediaModal
        showModal={isViewMediaOpen}
        toggleModal={toggleViewModal}
        mediaFile={selectedViewMedia}
        onUpdate={onUpdateMedia}
        onDelete={onDeleteMedia}
      />
    </>
  );
};
export default MediaLibraryWindow;
