import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';

const PreviewDisplay = ({ globalImageData, globalTextData, previewSize = "large", isVerticalScreen = false, index=0 }) => {
    useEffect(() => {
        let timeoutIdSlide;
        function showSlide(slideIndex) {
            const slides = document.querySelectorAll(`#slider-${index} .slide`);
            slides.forEach((slide, i) => {
                slide.style.display = i === slideIndex ? 'block' : 'none';
            });
        }

        function updateSlides(data) {
            if (data.length) {
                const sliderContainer = document.getElementById(`slider-${index}`);
                sliderContainer.innerHTML = '';
                data.forEach((item, i) => {
                    const slideElement = document.createElement('div');
                    slideElement.classList.add('slide');

                    if (item.media.media_content_type === "Image") {
                        const imgElement = document.createElement('img');
                        imgElement.src = item.media.url;
                        imgElement.alt = 'Slide Image';
                        slideElement.appendChild(imgElement);
                        sliderContainer.appendChild(slideElement);
                    } else if (item.media.media_content_type === "Video") {
                        const videoElement = document.createElement('video');
                        videoElement.src = item.media.url;
                        videoElement.autoplay = true;
                        videoElement.muted = true;
                        // Ensure the video starts from the beginning when shown again
                        videoElement.addEventListener('ended', function () {
                            videoElement.currentTime = 0;
                        });
                        // Play the video when it can play
                        videoElement.addEventListener('canplay', function () {
                            videoElement.play();
                        });
                        slideElement.appendChild(videoElement);
                        sliderContainer.appendChild(slideElement);
                    } else {
                        const webElement = document.createElement('iframe');
                        webElement.src = item.media.url;
                        slideElement.appendChild(webElement);
                        sliderContainer.appendChild(slideElement);
                    }
                });

                let currentIndex = 0;

                function recursiveUpdater() {
                    showSlide(currentIndex);

                    timeoutIdSlide = setTimeout(() => {
                        currentIndex = (currentIndex + 1) % data.length;
                        recursiveUpdater();
                    }, data[currentIndex].duration * 1000);
                }
                recursiveUpdater();
            }
        }
        updateSlides(globalImageData);
        // Cleanup function to clear timeouts when the component unmounts or when data changes
        console.log("images", globalImageData);
        return () => {
            if (timeoutIdSlide) {
                clearTimeout(timeoutIdSlide);
            }
        };
    }, [globalImageData, index]);

    useEffect(() => {
        function updateNewsTicker(data) {
            const newsTicker = document.getElementById(`news-ticker-${index}`);
            if (data.length) {
                const result = data.map((itm, i) => (
                    <React.Fragment key={i}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512">
                            <path fill="#fb0909" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
                        </svg>
                        <span className="news-item">{itm.text}</span>
                    </React.Fragment>
                ));
                newsTicker.innerHTML = renderToString(<div className="news">{result}</div>);
            } else {
                newsTicker.innerHTML = '';
            }
        }
        updateNewsTicker(globalTextData);
    }, [globalTextData, index]);

    return (
        <div className={`device-container ${previewSize === "small" ? "device-container-sm" : ""} ${isVerticalScreen ? "device-container-vertical" : ""}`}>
            <div className="slider" id={`slider-${index}`}>
            </div>
            <div className="device-bottom">
                <div className="news-ticker" id={`news-ticker-${index}`}></div>
            </div>
        </div>
    );
};

export default PreviewDisplay;
