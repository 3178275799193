import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { updateAsset, deleteAsset } from "../../../services/OtherService";
import { toast } from "react-toastify";


const ViewMediaModal = ({ showModal, toggleModal, mediaFile, onUpdate, onDelete }) => {
  const [updateMedia, setUpdateMedia] = useState("");
  const [previousState, setPreviousState] = useState("");
  const [newName, setNewName] = useState({
    name: "",
    extension: "",
  });
  const [newWebPage, setNewWebPage] = useState({
    name: "",
    url: ""
  })
  console.log(newName)
  useEffect(() => {
    if (!showModal) {
      // Reset state when modal is closed
      setUpdateMedia("");
      setNewName({ name: "", extension: "" });
    }
  }, [showModal]);

  const updateMediaFile = (updateType) => {
    setPreviousState(updateMedia);
    setUpdateMedia(updateType);

    if (updateType === "RENAME") {
      const fileName = mediaFile.name;
      const lastDotIndex = fileName.lastIndexOf(".");

      if (lastDotIndex !== -1) {
        setNewName({
          name: fileName.substring(0, lastDotIndex),
          extension: fileName.substring(lastDotIndex),
        });
      } else {
        setNewName({
          name: fileName,
          extension: "",
        });
      }
    }
    if (updateType === "EDIT"){
      setNewWebPage({
        name: mediaFile.name,
        url: mediaFile.media.url
      })
    }
  };

  const handleNameChange = (e) => {
    setNewName({ ...newName, name: e.target.value });
    setNewWebPage({ ...newWebPage, name: e.target.value })
  };

  const handleUrlChange = (e) => {
    setNewWebPage({ ...newWebPage, url: e.target.value });
  };

  const handleCancel = () => {
    setUpdateMedia(previousState); // Revert to the previous state
  };

  const handleSave = async () => {
    try {
      const data = {
        id: mediaFile.id,
        name: updateMedia === "RENAME" ? `${newName.name}${newName.extension}` : newWebPage.name,
        update_type: updateMedia === "RENAME" ? "RENAME" : "WEBPAGE",
      };

      if (updateMedia === "EDIT") {
        data.url = newWebPage.url;
      }

      const resp = await updateAsset(data);
      const {message, result} = resp.data
      toast.success(message);
      setUpdateMedia("")
      onUpdate(result)
      toggleModal(); 
    } catch (error) {
      console.error('Error updating media:', error);
      toast.error('Failed to update the media');
    }
  };

  const handleDelete = async () => {
    try {
      const resp = await deleteAsset(mediaFile.id);
      toast.success(resp.data.message);
      onDelete(mediaFile.id)
      toggleModal(); // Close the modal
    } catch (error) {
      console.error('Error deleting media:', error);
      toast.error('Failed to delete the media');
    }
  };

  return (
    <Modal
      className="modal fade modal-lg"
      id="foldermodal"
      show={showModal}
      onHide={toggleModal}
    >
      <div className="modal-content">
        <div className="modal-body">
          {mediaFile && (
            <>
              {mediaFile.media?.media_content_type === "Image" ? (
                <img
                  className="img-thumbnail"
                  src={mediaFile.media?.url}
                  alt="folder"
                />
              ) : mediaFile.media?.media_content_type === "Video" ? (
                <video
                  src={mediaFile.media?.url}
                  className="w-100"
                  controls
                ></video>
              ) : (
                <iframe
                  src={mediaFile.media?.url}
                  className="w-100"
                  style={{ height: "50vh" }}
                ></iframe>
              )}
              <h6 className="mt-2 ">{mediaFile.name}</h6>

              {updateMedia === "" ? (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {mediaFile.media?.media_content_type === "WebPage" ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-info"
                        onClick={() => updateMediaFile("EDIT")}
                      >
                        Edit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-info"
                        onClick={() => updateMediaFile("RENAME")}
                      >
                        Rename
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger ms-2"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              ) : updateMedia === "RENAME" ? (
                <div className="card-body">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={newName.name}
                      onChange={handleNameChange}
                      aria-describedby="basic-addon2"
                      id="name"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      {newName.extension}
                    </span>
                  </div>
                  <div
                    className="mt-2 d-flex"
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : updateMedia === "EDIT" ? (
                <div className="card-body">
                  <h4>EDIT WEB PAGE</h4>
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={newWebPage.name}
                      onChange={handleNameChange}
                      id="name"
                    />
                  </div>
                  <label htmlFor="url" className="form-label">
                    Url
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={newWebPage.url}
                      onChange={handleUrlChange}
                      id="url"
                    />
                  </div>
                  <div
                    className="mt-2 d-flex"
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary me-2"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMediaModal;
