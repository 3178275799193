import React, { useState, useEffect } from "react";
import { createLayout, getLayoutList } from "../../../services/ProductService";
import PreviewDisplay from "./PreviewScreen";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import { CancelRequest } from "../../../services/AxiosInstance";
import Pagination from "../../constant/Pagination";

const Layout = ({ ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [layoutForm, setLayoutForm] = useState({ name: '', screen_width: '', screen_height: '' })
  const recordsPage = 10;
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true);
    getLayoutList(currentPage, recordsPage, "")
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setIsLoading(false);
      });
  }, []);


  const toggleModal = () => {
    if (showModal) {
      setLayoutForm({ name: '', screen_width: '', screen_height: '' });
    }
    setShowModal(!showModal)
  }

  const handleChangeLayoutInput = (e) => {
    const { name, value } = e.target
    setLayoutForm({ ...layoutForm, [name]: value })
  }

  
  const handlePagination = (page) => {
    setIsLoading(true)
    const new_page = page.selected + 1
    getLayoutList(new_page, recordsPage, searchQuery).then((resp) => {
        setData(resp.data.data)
        setTotal(resp.data.total)
        setCurrentPage(new_page)
        setIsLoading(false)
    }).catch((error) => {
        alert(error.response.data.message)
        setIsLoading(false)
    })
}


  const handleSearchChange = (e) => {
    const { value } = e.target
    if (value.length > 0) {
      CancelRequest()
    }
    getLayoutList(currentPage, recordsPage, value).then((resp) => {
      if (resp) {
        setData(resp.data.data)
        setTotal(resp.data.total)
      }
    }).catch((error) => {
      console.log(error.response.data.message)
    })
    setSearchQuery(value)
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (Object.values(layoutForm).some(item => item.length === 0)) {
        toast.error("Fill all the Input Fields")
        return false
      }
      const resp = await createLayout(layoutForm)
      const resp_data = resp.data.data
      setData([resp_data, ...data])
      setTotal(total + 1)
      toast.success(resp.data.message)
      toggleModal()
    } catch (error) {
      console.log(error)
      //toast.error(error.response.data.message)
    };

  }





  return (
    <div className="container-fluid">
      <div className="card">
        <div className="d-flex justify-content-between text-wrap align-items-center border-bottom p-3">
          <h4 className="heading mb-0">Layout List</h4>
          <div className='d-flex'>
            <div className='form-group me-5'>
              <input type="search" name="serach" className="form-control "
                placeholder="Search"
                value={searchQuery} onChange={handleSearchChange} />
            </div>
            <button className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
              onClick={() => toggleModal()}>Add Layout</button>
          </div>
        </div>
        <div className="card-body">
          {isLoading ? (
            <h3>Loading ....</h3>
          ) :
            <div>
              <div className="row">
                {data.map((item, index) => (
                  <div className="col-xl-4" key={index}>
                    <div className={`d-flex flex-column justify-content-center ${item.screen_height > item.screen_width && 'align-items-center'}`}>
                      <PreviewDisplay
                        globalImageData={item.media_playlist}
                        globalTextData={item.text_playlist}
                        previewSize="small"
                        isVerticalScreen={item.screen_height > item.screen_width}
                        index={index}
                      />
                      <h4 className="text-primary text-center my-2 c-pointer" onClick={() => navigate(`/layout/${item.id}`)}> {item.name}</h4>
                    </div>
                  </div>
                ))
                }
              </div>
              {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                currentPage={currentPage}
                recordsPage={recordsPage}
                dataLength={total}
                handlePagination={handlePagination}
              />}
            </div>
          }
        </div>
      </div>

      <Modal show={showModal}
        onHide={() => toggleModal()}
        className='modal'>

        <div className='modal-content' >
          <div className="modal-header">
            <h5 className="modal-title" id="#gridSystemModal">Add User</h5>
            <button type="button" className="btn-close"
              onClick={() => toggleModal()}>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
                      <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
                        value={layoutForm.name} name='name' onChange={handleChangeLayoutInput} required />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="screen_width" className="form-label">Screen Width <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="screen_width" placeholder="Enter Screen Width"
                        value={layoutForm.screen_width} name='screen_width' onChange={handleChangeLayoutInput} required />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="screen_height" className="form-label">Screen Height <span className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="screen_height" placeholder="Enter Screen Height"
                        value={layoutForm.screen_height} name='screen_height' onChange={handleChangeLayoutInput} required />
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary me-1">Create</button>
                  <button type="button" onClick={() => toggleModal()} className="btn btn-danger light ms-1">Cancel</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
  };
};

export default connect(mapStateToProps)(Layout);
