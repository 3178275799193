import React, { useRef, useState } from 'react'
import { updateLayoutTextPlaylist } from '../../../services/ProductService'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

const MAX_WORD_LENGTH = 300

const initialTextItemInput = { id: null, value: '', isVisible: false, isError: false }
const TextPlaylistComponent = ({ ...props }) => {
    const { data, setData, textPlaylist, setTextPlaylist } = props
    const dragStartItem = useRef()
    const dragOverItem = useRef()
    const [isEdit, setIsEdit] = useState(false)
    const [textItemInput, setTextItemInput] = useState(initialTextItemInput)

    const toggleEdit = () => {
        setIsEdit(!isEdit)
    }
    const swapArrayIndex = (childIndex1, childIndex2) => {
        const newData = [...textPlaylist]
        const temp = newData[childIndex1]
        newData[childIndex1] = newData[childIndex2]
        newData[childIndex2] = temp
        setTextPlaylist(newData)
    }

    const handleDragStart = (e, index) => {
        dragStartItem.current = JSON.stringify({ index })
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        const { index: startIndex } = JSON.parse(dragStartItem.current)
        dragOverItem.current = JSON.stringify({ index })
        if (startIndex !== index) {
            e.target.id = `drag-over-playlist-text-item-${index}`
            e.target.classList.add('dragover')
        }
    }

    const handleDragLeave = (e, index) => {
        e.preventDefault()
        const { index: startIndex } = JSON.parse(dragStartItem.current)
        if (startIndex !== index) {
            e.target.classList.remove('dragover')
        }
    }

    const handleDragEnd = (e) => {
        e.preventDefault()
        if (dragStartItem !== null && dragOverItem.current !== null) {
            const { index: startIndex } = JSON.parse(dragStartItem.current)
            const { index: overIndex } = JSON.parse(dragOverItem.current)
            if (startIndex !== overIndex) {
                const dragoverDiv = document.getElementById(`drag-over-playlist-text-item-${overIndex}`)
                if (dragoverDiv) {
                    dragoverDiv.classList.remove("dragover")
                    dragoverDiv.id = ""
                }
                swapArrayIndex(startIndex, overIndex)
            }

        }
        dragOverItem.current = null
        dragStartItem.current = null
    }

    const handleAddTextPlaylist = () => {
        try {
            const { id, value } = textItemInput
            if (value.length >= 30 && value.length <= MAX_WORD_LENGTH) {
                const playlistItem = { id, text: value, index: 0 }
                const newTextPlaylist = [...textPlaylist, playlistItem]
                setTextPlaylist(newTextPlaylist)
                setTextItemInput(initialTextItemInput)
            } else {
                setTextItemInput({ ...textItemInput, isError: true })
            }

        } catch (error) {
            console.log("error", error)
        }

    }

    const handleEditTextPlaylist = (childIndex) => {
        try {
            const { value } = textItemInput
            if (value.length >= 30 && value.length <= MAX_WORD_LENGTH) {
                setTextPlaylist((prevParentList) => {
                    const newParentList = [...prevParentList]; // Shallow copy of the parent list
                    newParentList[childIndex] = { ...newParentList[childIndex], text: value };
                    return newParentList;
                });
                setTextItemInput(initialTextItemInput)
            } else {
                setTextItemInput({ ...textItemInput, isError: true })
            }

        } catch (error) {
            console.log("error", error)
        }

    }

    const handleTextDelete = (childIndex) => {
        const newTextPlaylist = [...textPlaylist]
        newTextPlaylist.splice(childIndex, 1)
        setTextPlaylist(newTextPlaylist)
    }

    const handleTextPlaylistCancel = () => {
        setTextPlaylist(data.text_playlist)
        setTextItemInput(initialTextItemInput)
        toggleEdit()

    }

    const handleTextPlaylistSave = () => {
        const textList = textPlaylist.map((item, index) => {
            return { id: item.id, index: index + 1, text: item.text }
        })
        const formData = {
            layout_id: data.id,
            text_list: textList
        }

        updateLayoutTextPlaylist(formData).then((resp) => {
            const respData = resp.data.data
            const newData = { ...data }
            newData.text_playlist = respData
            setData(newData)
            setTextPlaylist(respData)
            toast.success(resp.data.message)
        }).catch((error) => {
            console.log(error.response.data.message)
        })
    }

    return (
        <div>
            <div className='tag-playlist'>

                {/* text playlist */}
                <div className='p-2 rounded'>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h4>Text Playlist</h4>
                        {isEdit ? (<div className='d-flex align-items-center'>
                            <button className='btn btn-sm btn-secondary light' onClick={() => handleTextPlaylistCancel()} >Cancel</button>
                            <button className='btn btn-sm btn-success ms-2' onClick={() => handleTextPlaylistSave()}>Save</button>
                        </div>) :
                            <button className='btn btn-sm btn-primary' onClick={toggleEdit} >Edit</button>
                        }
                    </div>

                    <div className="bg-white p-3 rounded">
                        {isEdit ? <div>
                            {textItemInput.isVisible && textItemInput.id === null ? (
                                <div className='d-flex align-items-center'>
                                    <div className='w-100'>
                                        <input type='text' className='form-control' autoFocus placeholder='Enter Text'
                                            value={textItemInput.value}
                                            onChange={(e) => {
                                                const { value } = e.target
                                                const isError = !(value.length >= 30 && value.length <= MAX_WORD_LENGTH)
                                                setTextItemInput({ ...textItemInput, value, isError })
                                            }
                                            } />
                                        {textItemInput.isError && <span className='text-danger'>character length must be betwwen 30 to 300</span>}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <button
                                            className="btn btn-xs btn-outline-success ms-2"
                                            disabled={!isEdit}
                                            onClick={() => handleAddTextPlaylist()}>
                                            <i className="fa-solid fa-check"></i>
                                        </button>

                                        <button
                                            className="btn btn-xs btn-outline-danger ms-2"
                                            disabled={!isEdit}
                                            onClick={() => setTextItemInput(initialTextItemInput)}>
                                            <i className="fa-solid fa-xmark"></i>
                                        </button>

                                    </div>
                                </div>
                            ) : <button className='btn btn-sm btn-outline-primary'
                                onClick={() => {
                                    setTextItemInput({ id: null, value: '', isVisible: true })
                                }} >Add New Text</button>}

                        </div> :
                            (<div className='d-flex flex-column align-items-center justify-content-center'>
                                <p className='text-center'>Click on Edit button to modify playlist</p>
                                <button className='btn btn-sm btn-outline-primary' disabled={true}>Add New Text</button>
                            </div>)
                        }
                    </div>

                    {textPlaylist.map((itm, index) => (
                        textItemInput.isVisible && textItemInput.id !== null && textItemInput.id === itm.id ? (
                            <div className='d-flex align-items-center bg-white p-3 rounded my-3' key={index}>
                                <div className='w-100'>
                                    <input type='text' className='form-control' autoFocus placeholder='Enter Text'
                                        value={textItemInput.value}
                                        onChange={(e) => setTextItemInput({ ...textItemInput, value: e.target.value })} />
                                    {textItemInput.isError && <span className='text-danger'>character length must be betwwen 30 to 300</span>}
                                </div>

                                <div className='d-flex align-items-center'>
                                    <button
                                        className="btn btn-xs btn-outline-success ms-2"
                                        disabled={!isEdit}
                                        onClick={() => handleEditTextPlaylist(index)}>
                                        <i className="fa-solid fa-check"></i>
                                    </button>

                                    <button
                                        className="btn btn-xs btn-outline-danger ms-2"
                                        disabled={!isEdit}
                                        onClick={() => setTextItemInput(initialTextItemInput)}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>

                                </div>
                            </div>
                        ) :
                            <div key={index} className='d-flex align-items-center justify-content-between my-2 bg-white p-3 rounded'
                                draggable={isEdit}
                                onDragStart={(e) => {
                                    if (isEdit) { handleDragStart(e, index) }
                                }}
                                onDragOver={(e) => {
                                    if (isEdit) { handleDragOver(e, index) }
                                }}
                                onDragLeave={(e) => {
                                    if (isEdit) { handleDragLeave(e, index) }
                                }}
                                onDragEnd={(e) => {
                                    if (isEdit) { handleDragEnd(e) }
                                }}>
                                <p>{itm.text}</p>

                                <Dropdown className='custom-dropdown'>
                                    <Dropdown.Toggle
                                        as="button"
                                        variant=""
                                        disabled={!isEdit}
                                        className="btn sharp btn-primary tp-btn"
                                        id="tp-btn"
                                    > <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => setTextItemInput({ id: itm.id, value: itm.text, isVisible: true })}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleTextDelete(index)}>Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default TextPlaylistComponent
