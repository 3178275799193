import React, { useRef, useState } from "react";
import ChooseMediaModal from "./ChooseMediaModal";
import { updateLayoutMediaPlaylist } from "../../../services/ProductService";
import { toast } from "react-toastify";

const MAX_DURATION = 10;

const MediaPlaylistComponent = ({ ...props }) => {
  const { data, setData, mediaPlaylist, setMediaPlaylist } = props;
  const [isEdit, setIsEdit] = useState(false)
  const dragStartItem = useRef();
  const dragOverItem = useRef();

  const swapArrayIndex = (childIndex1, childIndex2) => {
    const newData = [...mediaPlaylist];
    const temp = newData[childIndex1];
    newData[childIndex1] = newData[childIndex2];
    newData[childIndex2] = temp;
    setMediaPlaylist(newData);
  };

  const handleMediaDuration = (childIndex, handleType) => {
    const newData = [...mediaPlaylist];
    const targetItem = newData[childIndex];

    let newDuration;

    if (handleType === "inc") {
      newDuration =
        targetItem.duration === MAX_DURATION
          ? MAX_DURATION
          : targetItem.duration + 1;
    } else {
      newDuration = targetItem.duration > 2 ? targetItem.duration - 1 : 1;
    }

    const updatedItem = { ...targetItem, duration: newDuration };
    newData[childIndex] = updatedItem;

    setMediaPlaylist(newData);
  };

  const handleDragStart = (e, index) => {
    dragStartItem.current = JSON.stringify({ index });
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const { index: startIndex } = JSON.parse(dragStartItem.current);
    dragOverItem.current = JSON.stringify({ index });
    if (startIndex !== index) {
      e.target.id = `drag-over-playlist-item-${index}`;
      e.target.parentNode.parentNode.classList.add("dragover");
    }
  };

  const handleDragLeave = (e, index) => {
    e.preventDefault();
    const { index: startIndex} = JSON.parse(dragStartItem.current);
    if (startIndex !== index) {
      e.target.parentNode.parentNode.classList.remove("dragover");
    }
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    if (dragStartItem !== null && dragOverItem.current !== null) {
      const { index: startIndex } = JSON.parse(dragStartItem.current);
      const { index: overIndex} = JSON.parse(dragOverItem.current);
      if (startIndex !== overIndex) {
        const dragoverDiv = document.getElementById(`drag-over-playlist-item-${overIndex}`);
        if (dragoverDiv) {
          dragoverDiv.parentNode.parentNode.classList.remove("dragover");
          dragoverDiv.id = "";
        }
        swapArrayIndex(startIndex, overIndex);
      }
    }
    dragOverItem.current = null;
    dragStartItem.current = null;
  };

  const handleAddMediaPlaylist = (playlistItem) => {
    setMediaPlaylist([...playlistItem, ...mediaPlaylist]);
  };

  const handleMediaDelete = (childIndex) => {
    const newMediaPlaylist = [...mediaPlaylist];
    newMediaPlaylist.splice(childIndex, 1);
    setMediaPlaylist(newMediaPlaylist);
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit)
  };

  const handleMediaPlaylistCancel = () => {
    setMediaPlaylist(data.media_playlist);
    toggleEdit()
  };

  const handleMediaPlaylistSave = () => {
    const mediaItems = mediaPlaylist.map((item, index) => {
      return {
        id: item.id,
        media_id: item.media.id,
        index: index + 1,
        duration: item.duration,
      };
    });
    const formData = {
      layout_id: data.id,
      media_items: mediaItems,
    };

    updateLayoutMediaPlaylist(formData)
      .then((resp) => {
        const respData = resp.data.data;
        const newData = { ...data };
        newData.media_playlist = respData;
        setData(newData);
        setMediaPlaylist(respData);
        toast.success(resp.data.message);
        toggleEdit()
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div>
      <div className="tag-playlist">
        {/* media playlist */}
        <div className="p-2 rounded">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h4>Media Playlist</h4>
            {isEdit ? (
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-secondary light"
                  onClick={() => handleMediaPlaylistCancel()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-success ms-2"
                  onClick={() => handleMediaPlaylistSave()}
                >
                  Save
                </button>
              </div>
            ) : (
              <button
                className="btn btn-sm btn-primary"
                onClick={toggleEdit}
              >
                Edit
              </button>
            )}
          </div>

          <div className="playlist-item">
            <div
              style={{ minWidth: "120px", width: "120px", height: "175px" }}
              className="bg-white p-1 rounded d-flex align-items-center justify-content-center"
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                {isEdit ? (
                  <ChooseMediaModal
                    handleAddMediaPlaylist={handleAddMediaPlaylist}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <p className="text-center">
                      Click on <span style={{ color: "Blue" }}>Edit</span> button to modify playlist
                    </p>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      disabled={true}
                    >
                      Add Media
                    </button>
                  </div>
                )}
              </div>
            </div>

            {mediaPlaylist.map((itm, index) => (
              <div
                key={index}
                className="d-flex flex-column mx-2 bg-white p-1 rounded"
              >
                <div
                  draggable={isEdit}
                  onDragStart={(e) => {
                    if (isEdit) {
                      handleDragStart(e, index);
                    }
                  }}
                  onDragOver={(e) => {
                    if (isEdit) {
                      handleDragOver(e, index);
                    }
                  }}
                  onDragLeave={(e) => {
                    if (isEdit) {
                      handleDragLeave(e, index);
                    }
                  }}
                  onDragEnd={(e) => {
                    if (isEdit) {
                      handleDragEnd(e);
                    }
                  }}
                >
                  {itm.media.media_content_type === "Image" ? (
                    <img
                      src={itm.media.url}
                      style={{
                        width: "120px",
                        height: "140px",
                        objectFit: "cover",
                        cursor: "move",
                      }}
                      alt="img"
                    />
                  ) : itm.media.media_content_type === "Video" ? (
                    <video
                      src={itm.media?.url}
                      style={{
                        width: "120px",
                        height: "140px",
                        objectFit: "cover",
                        cursor: "move",
                      }}
                      controls
                    ></video>
                  ) : (
                    <iframe
                      src={itm.media?.url}
                      style={{
                        width: "120px",
                        height: "140px",
                        objectFit: "cover",
                        cursor: "move",
                      }}
                    ></iframe>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <button
                    className="btn btn-xs btn-primary smbtn"
                    disabled={
                      !isEdit ||
                      itm.media.media_content_type === "Video"
                    }
                    onClick={() =>
                      handleMediaDuration(index, "dec")
                    }
                  >
                    -
                  </button>
                  <span className="border border-primary flex-grow-1 text-center text-primary mx-1 rounded">
                    {itm.duration}
                  </span>
                  <button
                    className="btn btn-xs btn-primary smbtn"
                    disabled={
                      !isEdit ||
                      itm.media.media_content_type === "Video"
                    }
                    onClick={() =>
                      handleMediaDuration(index, "inc")
                    }
                  >
                    +
                  </button>
                  <button
                    className="btn btn-xs btn-outline-danger smbtn ms-2"
                    disabled={!isEdit}
                    onClick={() => handleMediaDelete(index)}
                  >
                    X
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex align-items-center justify-content-between mt-3">
            <div>
              <span className="ms-2">
                <i className="fa-solid fa-image text-primary me-1"></i>
                {
                  mediaPlaylist.filter(
                    (img) => img.media.media_content_type === "Image"
                  ).length
                }
              </span>
              <span className="ms-2">
                <i className="fa-solid fa-video text-primary me-1"></i>
                {
                  mediaPlaylist.filter(
                    (vid) => vid.media.media_content_type === "Video"
                  ).length
                }
              </span>
              <span className="ms-2">
                <i className="fa-brands fa-chrome text-primary me-1"></i>
                {
                  mediaPlaylist.filter(
                    (wp) => wp.media.media_content_type === "WebPage"
                  ).length
                }
              </span>
            </div>
            <h4>
              Duration:{" "}
              <span className="text-primary">
                {mediaPlaylist.reduce((acc, item) => acc + item.duration, 0)}
                /150s
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPlaylistComponent;
