import axiosInstance from './AxiosInstance';
const debiceUrl = "api/device"
const userUrl = "api/user"
const designationUrl = "api/designation"
const assetUrl = "api/media_library"
const departmentUrl = "api/department"
const prodCatUrl = "api/product_category"

// user
export function getUserList(page, page_size, query) {
    return axiosInstance.get(`${userUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUser(empId) {
    return axiosInstance.get(`${userUrl}/${empId}`);
}

export function createUser(empData) {
    return axiosInstance.post(userUrl, empData);
}

export function updateUser(empData) {
    return axiosInstance.put(userUrl, empData);
}

export function deleteUser(empId) {
    return axiosInstance.delete(`${userUrl}/${empId}`);
}

// department
export function getDepartmentList(page, page_size, query) {
    return axiosInstance.get(`${departmentUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getDepartment(empId) {
    return axiosInstance.get(`${departmentUrl}/${empId}`);
}


export function createDepartment(Data) {
    return axiosInstance.post(departmentUrl, Data);
}

export function updateDepartment(Data) {
    return axiosInstance.put(departmentUrl, Data);
}
//Asset
export function getAssetList(page, page_size, query, id = null) {
    if (id) {
        return axiosInstance.get(`${assetUrl}/${id}?page=${page}&page_size=${page_size}&query=${query}`);
    }
    return axiosInstance.get(`${assetUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getAsset(empId) {
    return axiosInstance.get(`${assetUrl}/${empId}`);
}


export function createAsset(Data) {
    return axiosInstance.post(assetUrl, Data);
}

export function updateAsset(Data) {
    return axiosInstance.put(assetUrl, Data);
}

export function deleteAsset(id) {
    return axiosInstance.delete(`${assetUrl}/${id}`);
}
// employee

export function getEmployeeList(page, page_size, query) {
    return axiosInstance.get(`${debiceUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getEmployee(empId) {
    return axiosInstance.get(`${debiceUrl}/${empId}`);
}

export function createDevice(empData) {
    return axiosInstance.post(debiceUrl, empData);
}

export function updateDevice(empData) {
    return axiosInstance.put(debiceUrl, empData);
}
export function resetDevice(data) {
    return axiosInstance.patch(debiceUrl, data);
}

export function deleteEmployee(empId) {
    return axiosInstance.delete(`${debiceUrl}/${empId}`);
}

// designation

export function getDesignationList() {
    return axiosInstance.get(`${designationUrl}`);
}

export function createDesignation(Data) {
    return axiosInstance.post(designationUrl, Data);
}

export function updateDesignation(Data) {
    return axiosInstance.put(designationUrl, Data);
}

// product Catgory

export function getProductCategoryList() {
    return axiosInstance.get(`${prodCatUrl}`);
}

export function createProductCategory(Data) {
    return axiosInstance.post(prodCatUrl, Data);
}

export function updateProductCategory(Data) {
    return axiosInstance.put(prodCatUrl, Data);
}

export function getAuthorized() {
    return axiosInstance.get('api/user/authorized');
}