import { Modal, Form } from "react-bootstrap"
import { useState } from "react"
import { toast } from 'react-toastify';
import { createAsset, updateAsset, deleteUser } from '../../../services/OtherService';


const FolderModal = ({ showModal, toggleModal, setData, folderName = '', parentId=null}) => {
    const [folderNameInput, setFolderNameInput] = useState(folderName);

    const handleOnhide = () => {
        setFolderNameInput('')
        toggleModal()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (folderName.length === 0) {
            try {
                const formData = {name: folderNameInput, parent_folder_id:parentId}
                const resp = await createAsset(formData)
                const resp_data = resp.data.data
                setData(resp_data)
                toast.success(resp.data.message)
                handleOnhide()

            } catch (error) {
                toast.error(error.response.data.message)
            };
        } else {
            try {
                const response = await updateAsset({})
                const resp_data = response.data
                toast.success(resp_data.message)
                handleOnhide()
            } catch (error) {
                console.log(error)
                if (error && error.response) {
                    toast.error(error.response.data.message)
                }
            };
        }
    }


    return (
        <Modal className="modal fade modal-sm"
            id="foldermodal"
            show={showModal}
            onHide={handleOnhide}>
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel1">Create Folder</h1>
                </div>
                <div className="modal-body">
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Folder Name <span className="text-danger">*</span></label>
                            <input type="text"
                                autoFocus
                                className="form-control"
                                id="name"
                                placeholder="Enter Folder Name"
                                value={folderNameInput}
                                name='name'
                                onChange={(e) => setFolderNameInput(e.target.value)}
                                required />
                        </div>

                        <div className="btn-div">
                            <button type="submit" className="btn btn-primary me-1">Create</button>
                            <button type="button" onClick={() => handleOnhide()} className="btn btn-danger light ms-1">Cancel</button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}

export default FolderModal