import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { searchProduct } from "../../../services/ProductService";
import { CancelRequest } from "../../../services/AxiosInstance";

const Header = () => {
	const [headerFix, setheaderFix] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [searchReasult, setSearchReasult] = useState([]);
	const navigate = useNavigate()

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	const toggleModal = () => {
		if (modalOpen) {
			setInputValue('');
			setSearchReasult([])
		}
		setModalOpen(!modalOpen)
	}

	const handleInputChange = (event) => {
		const newInputValue = event.target.value;
		if (newInputValue.length > 0) {
			CancelRequest()
		}
		searchProduct(newInputValue).then((response) => {
			if (response) {
				setSearchReasult(response.data.data)
			}
		}).catch((error) => {
			console.log(error.response.data.message)
		});
		setInputValue(newInputValue);
	};


	const handleResultClick = (prodId) => {
		setInputValue('');
		toggleModal()
		setSearchReasult([])
		navigate(`/material/${prodId}`)
	};
	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div className={`search-bar ${searchReasult.length && 'show-result'}`}>
								<div className="input-group search-area">
									<input type="text" className="form-control rounded-0"
										placeholder="Search ..."
										//onChange={handleInputChange}
										value={inputValue} />
									<span className="input-group-text rounded-0">
										{inputValue.length > 0 ? (
											<span onClick={() => {
												setInputValue('')
												setSearchReasult([])
											}}>
												<i className="fa-regular fa-circle-xmark text-primary c-pointer fa-xl"></i>
											</span>) : (<span>
												<i className="fa-solid fa-magnifying-glass text-primary fa-xl"></i>
											</span>)}
									</span>
								</div>
								<div className="search-area-result">
									{searchReasult.map((item, index) => (
										<div key={index} className="search-area-result-item">
											<span onClick={() => handleResultClick(item.id)}>{item.name}</span>
										</div>
									))}
								</div>
							</div>
						</div>

						<ul className="navbar-nav header-right">
							
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
